import { render, staticRenderFns } from "./Pengaturan.vue?vue&type=template&id=41ffdfa9&scoped=true&"
import script from "./Pengaturan.vue?vue&type=script&lang=js&"
export * from "./Pengaturan.vue?vue&type=script&lang=js&"
import style0 from "./Pengaturan.vue?vue&type=style&index=0&id=41ffdfa9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41ffdfa9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBadge,VCard,VCol,VContainer,VFileInput,VIcon,VImg,VRow})

<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="400"
      persistent
    >
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              Ganti Email
            </span>
            <span
              style="cursor: pointer"
              @click="close"
            >
              <v-icon>mdi-close</v-icon>
            </span>
          </v-col>
        </v-row>
        <template v-if="dataModal !== null">
          <v-row>
            <v-col>
              <div class="d-flex flex-column my-container">
                <span class="txt12-white">
                  Email Anda saat ini
                </span>
                <span class="txt16-white-bold">
                  {{ dataModal.email }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="txt12-gray50-bold">
              Email baru
            </v-col>
          </v-row>
          <v-row class="mt-n4 mb-8">
            <v-col>
              <v-text-field
                v-model="email"
                placeholder="Masukkan email baru"
                autocomplete="off"
                color="#36AC87"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                :disabled="disabled"
                class="text-capitalize white--text"
                color="#36AC87"
                block
                x-large
                @click="dialog = true"
              >
                Simpan Perubahan
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
    <modal-konfirmasi
      :show="dialog"
      @submit="submit"
    />
  </div>
</template>

<script>
  import ModalKonfirmasi from '../komponen/modal/ModalKonfirmasi.vue'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      ModalKonfirmasi,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      dataModal: {
        type: Object,
        default: null,
      },
    },

    data: () => ({
      disabled: false,
      email: '',
      dialog: false,
    }),

    computed: {},

    watch: {},

    created () {},

    methods: {
      close () {
        this.$emit('close')
      },

      submit (p) {
        if (p === 0) {
          this.dialog = false
          return
        }
        this.dialog = false
        this.disabled = true
        const requestBody = {
          email: this.email,
        }
        axios.post('/v1/admin/setting/update/email', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.disabled = false
            this.email = ''
            this.$toast.success(res.data.message)
            this.close()
          }
        })
          .catch((e) => {
            this.disabled = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt8-gray50 {
  @extend .p-2;
  color: $gray-50;
}

.txt12-white {
  @extend .p-2;
  color: white;
}

.txt12-gray50-bold {
  @extend .p-2;
  color: $gray-50;
  font-weight: bold;
}

.txt16-white-bold {
  @extend .p-1;
  font-weight: bold;
  color: white;
}

.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.my-container {
  background: $green-2;
  border-radius: 4px;
  padding: 16px;
}
</style>

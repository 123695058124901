<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #F2F2F2;
    "
  >
    <template v-if="dataCard !== null">
      <div class="card-member-new pt-4 px-4">
        <div
          class="d-inline-block"
          style="position: relative; top: 130px; left: 110px;"
        >
          <div>{{ dataCard.name.toUpperCase() || '-' }}</div>
          <div>{{ dataCard.no_kta.toUpperCase() || '-' }}</div>
        </div>
      </div>
      <!-- <template v-if="dataCard.member_card_type === 1">
        <div class="card-member-1 pt-4 px-4">
          <div class="d-flex">
            <v-img
              src="@/assets/member_card_header_1.png"
              width="68px"
              height="74px"
              contain
            />
          </div>

          <div class="d-flex justify-space-between mt-4">
            <div class="d-flex flex-column">
              <span class="card-member-name">
                {{ dataCard.name || '-' }}
              </span>
              <span class="card-member-code mt-4">
                {{ dataCard.no_kta || '-' }}
              </span>
            </div>

            <div>
              <template v-if="dataCard.photo">
                <v-img
                  :src="dataCard.photo.url"
                  alt="profileimage"
                  width="100px"
                  max-height="90px"
                  contain
                />
              </template>
              <template v-else>
                <v-icon size="100">
                  mdi-account-circle
                </v-icon>
              </template>
            </div>
          </div>
        </div>
      </template> -->

      <!-- <template v-if="dataCard.member_card_type === 2">
        <div class="card-member-2 pt-4 px-4">
          <div class="d-flex">
            <v-img
              src="@/assets/member_card_header_2.png"
              width="68px"
              height="74px"
              contain
            />
          </div>

          <div class="d-flex justify-space-between mt-4">
            <div class="d-flex flex-column">
              <span class="card-member-name">
                {{ dataCard.name || '-' }}
              </span>
              <span class="card-member-code mt-4">
                {{ dataCard.no_kta || '-' }}
              </span>
            </div>

            <div>
              <template v-if="dataCard.photo">
                <v-img
                  :src="dataCard.photo.url"
                  alt="profileimage"
                  width="100px"
                  contain
                />
              </template>
              <template v-else>
                <v-icon size="100">
                  mdi-account-circle
                </v-icon>
              </template>
            </div>
          </div>
        </div>
      </template> -->

      <!-- <template v-if="dataCard.member_card_type === 3">
        <div class="card-member-3 pt-4 px-4">
          <div class="d-flex">
            <v-img
              src="@/assets/member_card_header_3.png"
              width="68px"
              height="74px"
              contain
            />
          </div>

          <div class="d-flex justify-space-between mt-4">
            <div class="d-flex flex-column">
              <span class="card-member-name">
                {{ dataCard.name || '-' }}
              </span>
              <span class="card-member-code mt-4">
                {{ dataCard.no_kta || '-' }}
              </span>
            </div>

            <div>
              <template v-if="dataCard.photo">
                <v-img
                  :src="dataCard.photo.url"
                  alt="profileimage"
                  width="100px"
                  contain
                />
              </template>
              <template v-else>
                <v-icon size="100">
                  mdi-account-circle
                </v-icon>
              </template>
            </div>
          </div>
        </div>
      </template> -->
    </template>

    <template v-if="dataUtama !== null">
      <v-card class="mt-6 mb-3 px-8 py-8">
        <v-row>
          <v-col
            cols="12"
            sm="3"
            class="text-center"
          >
            <v-badge
              bottom
              offset-x="25"
              offset-y="25"
              avatar
              color="white"
            >
              <v-avatar
                size="100"
              >
                <template v-if="dataUtama.photo">
                  <v-img
                    :src="dataUtama.photo.url"
                    alt="profileimage"
                  />
                </template>
                <template v-else>
                  <v-icon size="100">
                    mdi-account-circle
                  </v-icon>
                </template>
              </v-avatar>
              <template
                v-slot:badge
              >
                <v-icon
                  color="green"
                  size="22"
                  @click="uploadFile"
                >
                  mdi-pencil-circle
                </v-icon>
                <v-file-input
                  id="upload-file-avatar"
                  accept="image/*"
                  style="display: none"
                  @change="upload"
                />
              </template>
            </v-badge>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-row>
              <v-col
                cols="12"
                class="txt20-gray50-bold"
              >
                {{ dataUtama.name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="txt12-gray50">
                Anggota
              </v-col>
              <v-col class="txt12-gray50-bold">
                {{ dataUtama.type_member }}
              </v-col>
              <v-col class="txt12-gray50">
                BPC
              </v-col>
              <v-col class="txt12-gray50-bold">
                {{ dataUtama.bpc }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="txt12-gray50">
                Jabatan
              </v-col>
              <v-col class="d-flex flex-column">
                <div
                  v-for="(v, i) in dataUtama.jabatan"
                  :key="'jabatan-' + i"
                  class="txt12-gray50-bold"
                >
                  {{ v }}
                </div>
              </v-col>
              <v-col class="txt12-gray50">
                BPD
              </v-col>
              <v-col class="txt12-gray50-bold">
                {{ dataUtama.bpd }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </template>

    <v-card class="mt-3">
      <div
        class="d-flex justify-space-between py-3 px-4"
        style="cursor: pointer"
        @click="dialog = true"
      >
        <span class="txt16-green2-bold">
          Data Pribadi
        </span>
        <span>
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </span>
      </div>
    </v-card>

    <v-card class="mt-3">
      <div
        class="d-flex justify-space-between py-3 px-4"
        style="cursor: pointer"
        @click="toPerusahaan"
      >
        <span class="txt16-green2-bold">
          Data Perusahaan
        </span>
        <span>
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </span>
      </div>
    </v-card>

    <v-card class="mt-3">
      <div
        class="d-flex justify-space-between py-3 px-4"
        style="cursor: pointer"
        @click="dialog2 = true"
      >
        <span class="txt16-green2-bold">
          Data Kontak Darurat
        </span>
        <span>
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </span>
      </div>
    </v-card>

    <v-card class="mt-3">
      <div
        class="d-flex justify-space-between py-3 px-4"
        style="cursor: pointer"
        @click="dialog3 = true"
      >
        <span class="txt16-green2-bold">
          Ganti Nomor Telepon
        </span>
        <span>
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </span>
      </div>
    </v-card>

    <v-card class="mt-3">
      <div
        class="d-flex justify-space-between py-3 px-4"
        style="cursor: pointer"
        @click="dialog4 = true"
      >
        <span class="txt16-green2-bold">
          Ganti Alamat E-mail
        </span>
        <span>
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </span>
      </div>
    </v-card>

    <v-card class="mt-3">
      <div
        class="d-flex justify-space-between py-3 px-4"
        style="cursor: pointer"
        @click="dialog5 = true"
      >
        <span class="txt16-green2-bold">
          Ganti Kata Sandi
        </span>
        <span>
          <v-icon>
            mdi-chevron-right
          </v-icon>
        </span>
      </div>
    </v-card>

    <modal-data-pribadi
      :show="dialog"
      :data-modal="dataDetail"
      @close="closeDialog"
    />

    <modal-data-darurat
      :show="dialog2"
      :data-modal="dataDarurat"
      @close="closeDialog"
    />

    <modal-ganti-telepon
      :show="dialog3"
      :data-modal="dataDetail"
      @close="closeDialog"
    />

    <modal-ganti-email
      :show="dialog4"
      :data-modal="dataDetail"
      @close="closeDialog"
    />

    <modal-ganti-password
      :show="dialog5"
      @close="closeDialog"
    />
  </v-container>
</template>

<script>
  // import aa from '../../../../../src/assets/member_card_bg_2.png'
  import ModalDataPribadi from './ModalDataPribadi.vue'
  import ModalDataDarurat from './ModalKontakDarurat.vue'
  import ModalGantiTelepon from './ModalGantiNomorTelepon.vue'
  import ModalGantiEmail from './ModalGantiEmail.vue'
  import ModalGantiPassword from './ModalGantiPassword.vue'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      ModalDataPribadi,
      ModalDataDarurat,
      ModalGantiTelepon,
      ModalGantiEmail,
      ModalGantiPassword,
    },

    data: () => ({
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      dataCard: null,
      dataUtama: null,
      dataDetail: null,
      dataDarurat: null,
      photoHash: '',
    }),

    computed: {},

    watch: {},

    created () {
      this.initialize()
    },

    methods: {
      closeDialog () {
        this.dialog = false
        this.dialog2 = false
        this.dialog3 = false
        this.dialog4 = false
        this.dialog5 = false
        this.initialize()
      },

      uploadFile () {
        document.getElementById('upload-file-avatar').click()
      },

      toPerusahaan () {
        this.$router.push({ name: 'PengaturanPerusahaan' })
      },

      initialize () {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')
        // if (userMenus.includes('xxx')) {
        //   this.xxx = true
        // }

        axios.post('/v1/admin/setting/get-current-user').then((res) => {
          if (res.data.status === 200) {
            this.dataUtama = res.data.data.main_data
            this.dataCard = res.data.data.card_data
            this.dataDetail = res.data.data.personal_data
            this.dataDarurat = res.data.data.emgc_data
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      saveButtonClick () {
        const requestBody = {
          hash_avatar: JSON.stringify(this.profilePictureUploadedHash),
        }

        axios.post('/v1/t/profile/personal/changeAvatar', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            this.initialize()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })

        this.dialog = false
      },

      vdropzoneFileAdded (file) {
        const dropzoneFiles = this.$refs.myVueDropzone.dropzone.files
        if (dropzoneFiles.length > 1) {
          document.querySelector('.dz-preview').remove()
          dropzoneFiles.shift()
        }

        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('image', file)

        axios.post(baseCDNurl + '/v1/t/profile/personal/uploadAvatar', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            document.querySelectorAll('.dz-details').forEach(el => el.remove())
            document.querySelectorAll('.dz-progress').forEach(el => el.remove())
            this.profilePictureUploadedHash.push(res.data.data.tmp_file_hash)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      changeAvatar () {
        this.profilePicture = {}
        this.profilePictureUploadedHash = []
        this.dialog = true
        this.$nextTick(() => {
          this.$refs.myVueDropzone.removeAllFiles()
        })
      },

      ubahPassword () {
        if (!this.fieldValidation()) return

        const requestBody = {
          old_pass: this.password.oldPassword,
          new_pass: this.password.newPassword,
          new_pass_verif: this.password.confirmPassword,
        }

        axios.post('/v1/t/profile/personal/changePwd', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.password.oldPassword = ''
            this.password.newPassword = ''
            this.password.confirmPassword = ''
            this.dialogUbahPassword = false
            this.$toast.success(res.data.message)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.textAlert = Object.values(e.response.data.error)[0]
            } else {
              this.textAlert = e.response.data.error
            }
            this.showAlert = true
          })
      },

      upload (e) {
        this.isLoading = true
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('file', e)
        requestBody.append('dest', 'member_photo')

        axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            this.isLoading = false
            this.photoHash = res.data.data.tmp_file_hash
            this.updatePhoto()
          }
        })
          .catch((e) => {
            this.isLoading = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      updatePhoto () {
        const reqBody = {
          hash_photo: this.photoHash,
        }
        axios.post('/v1/admin/setting/update/photo', reqBody).then(res => {
          if (res.status === 200) this.initialize()
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@font-face {
  font-family: RighterousRegular;
  src: url("/fonts/Righteous-Regular.ttf");
}

.txt12-gray50 {
  @extend .p-2;
  color: $gray-50;
}

.txt12-gray50-bold {
  @extend .p-2;
  font-weight: bold;
  color: $gray-50;
}

.txt16-green2-bold {
  @extend .p-1;
  font-weight: bold;
  color: $green-2;
}

.txt20-gray50-bold {
  @extend .h-5;
  font-weight: bold;
  color: $gray-50;
}

.card-member-1 {
  background-image: url('../../../../../src/assets/member_card_bg_1.png');
  width: 360px;
  height: 205px;
  border-radius: 4px;
}

.card-member-2 {
  background-image: url('../../../../../src/assets/member_card_bg_2.png');
  width: 360px;
  height: 205px;
  border-radius: 4px;
}

.card-member-3 {
  background-image: url('../../../../../src/assets/member_card_bg_3.png');
  width: 360px;
  height: 205px;
  border-radius: 4px;
}

.card-member-new {
  background-image: url('../../../../../src/assets/member_card_new.png');
  width: 360px;
  height: 205px;
  background-size: 100% 100%;
  @extend .p-1;
  font-weight: bold;
  color: #FAEA20;
  // font-family: RighterousRegular;
  letter-spacing: 3px;
}

.card-member-name {
  @extend .p-1;
  font-weight: bold;
  color: #FAEA20;
}

.card-member-code {
  @extend .h-5;
  color: #FAEA20;
}
</style>

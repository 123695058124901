<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="400"
      persistent
    >
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              Ganti Password
            </span>
            <span
              style="cursor: pointer"
              @click="close"
            >
              <v-icon>mdi-close</v-icon>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label for="password-lama">Password Lama</label>
            <v-text-field
              id="password-lama"
              v-model="password"
              outlined
              placeholder="Masukan Password Lama"
              color="#36AC87"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showPassword = !showPassword)"
            />
          </v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col>
            <label for="password-baru">Password Baru</label>
            <v-text-field
              id="password-baru"
              v-model="password2"
              outlined
              placeholder="Masukan Password Baru"
              color="#36AC87"
              :type="showPassword2 ? 'text' : 'password'"
              :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showPassword2 = !showPassword2)"
            />
          </v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col>
            <label for="password-konfirmasi">Konfirmasi Password Baru</label>
            <v-text-field
              id="password-konfirmasi"
              v-model="password3"
              outlined
              placeholder="Konfirmasi Password Baru"
              color="#36AC87"
              :type="showPassword3 ? 'text' : 'password'"
              :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showPassword3 = !showPassword3)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              :disabled="disabled"
              class="text-capitalize white--text"
              color="#36AC87"
              block
              x-large
              @click="dialog = true"
            >
              Simpan Perubahan
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <modal-konfirmasi
      :show="dialog"
      @submit="submit"
    />
  </div>
</template>

<script>
  import ModalKonfirmasi from '../komponen/modal/ModalKonfirmasi.vue'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      ModalKonfirmasi,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      disabled: false,
      password: '',
      password2: '',
      password3: '',
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      dialog: false,
    }),

    computed: {},

    watch: {},

    created () {},

    methods: {
      close () {
        this.$emit('close')
      },

      submit (p) {
        if (p === 0) {
          this.dialog = false
          return
        }
        this.dialog = false
        this.disabled = true
        const requestBody = {
          password_old: this.password,
          password: this.password2,
          password_confirm: this.password3,
        }
        axios.post('/v1/admin/setting/update/password', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.disabled = false
            this.password = ''
            this.password2 = ''
            this.password3 = ''
            this.$toast.success(res.data.message)
            this.close()
          }
        })
          .catch((e) => {
            this.disabled = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt8-gray50 {
  @extend .p-2;
  color: $gray-50;
}

.txt12-white {
  @extend .p-2;
  color: white;
}

.txt12-gray50-bold {
  @extend .p-2;
  color: $gray-50;
  font-weight: bold;
}

.txt16-white-bold {
  @extend .p-1;
  font-weight: bold;
  color: white;
}

.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.my-container {
  background: $green-2;
  border-radius: 4px;
  padding: 16px;
}
</style>

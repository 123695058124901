<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="600"
      persistent
    >
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              Data Kontak Darurat
            </span>
            <span
              style="cursor: pointer"
              @click="close"
            >
              <v-icon>mdi-close</v-icon>
            </span>
          </v-col>
        </v-row>
        <template v-if="dataModal !== null">
          <v-row>
            <v-col
              class="txt16-gray50"
              cols="6"
              sm="4"
            >
              Nama Lengkap
            </v-col>
            <v-col
              class="txt16-gray50-bold"
              cols="6"
              sm="8"
            >
              {{ dataModal.name || '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="txt16-gray50"
              cols="6"
              sm="4"
            >
              Nomer Telepon
            </v-col>
            <v-col
              class="txt16-gray50-bold"
              cols="6"
              sm="8"
            >
              {{ dataModal.phone || '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="txt16-gray50"
              cols="6"
              sm="4"
            >
              Hubungan
            </v-col>
            <v-col
              class="txt16-gray50-bold"
              cols="6"
              sm="8"
            >
              {{ dataModal.connection || '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="txt16-gray50"
              cols="6"
              sm="4"
            >
              Alamat
            </v-col>
            <v-col
              class="txt16-gray50-bold"
              cols="6"
              sm="8"
            >
              {{ dataModal.address || '-' }}
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      dataModal: {
        type: Object,
        default: null,
      },
    },

    data: () => ({}),

    computed: {},

    watch: {},

    created () {},

    methods: {
      close () {
        this.$emit('close')
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
